import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { connect } from "react-redux"
import * as actions from "@redux/actions"

import Layout from "@components/Layout"
import SEO from "@components/Seo"

const AdminPage = ({ authenticated, user }) => {
  // - - - - - - - - - - - - - - - - - - - - -
  const data = useStaticQuery(graphql`
    query AdminQuery {
      banner: allFile(filter: { name: { eq: "coaching_portal_1440x400" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            id
          }
        }
      }
    }
  `)
  // - - - - - - - - - - - - - - - - - - - - -
  const banner = data.banner.edges[0].node.childImageSharp.fluid
  return (
    <Layout fluid={banner} alt={"Neil Hill Coaching"} height={300}>
      <div className="container-fluid">
        <SEO title="Y3T Admin" />
        <h1>Admin</h1>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
  }
}
export default connect(mapStateToProps, actions)(AdminPage)
